import AuthLayout from '@app/components/auth-layout';
import Button from '@app/components/button';
import UpcomingAndOpenBids from '@app/components/upcoming-and-open-bids';
import { OPEN_BIDS_PAGE } from '@app/constants/routes';
import { useSession } from 'next-auth/react';
import BidStats from '@app/components/bids-stats';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { formatDate } from '@app/lib/date-fns';
import { useThemeContext } from '@app/contexts/theme-context';
import { useUserContext } from '@app/contexts/user-context';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';

const DashboardAuthImage = ({ userImg }: { userImg: any }) => (
  userImg
    ? <img className="md:h-16 md:w-16 h-12 w-12 rounded-3xl block" src={userImg} alt={userImg} />
    : (
      <div
        className="md:h-16 md:w-16 h-12 w-12 rounded-2xl md:rounded-3xl flex items-center
        justify-center bg-secondary px-3 py-1
        text-2xl md:text-3xl text-white"
      >
        <Icon path={mdiAccount} size={2} />
      </div>
    )
);

const DynamicHowToBidVideo = dynamic(() => import('@app/components/how-bid-video'), {
  ssr: false,
});

const DynamicMobileDashboardAllOpenBids = dynamic(() => import('@app/components/mobile-dashboard-all-open-bids'), {
  ssr: false,
});

const DynamicVendorOpenBids = dynamic(() => import('@app/components/vendor-open-bids'), {
  ssr: false,
});

const DynamicRecentlyClosedBids = dynamic(() => import('@app/components/recently-closed-bids'), {
  ssr: false,
});

const DynamicMobileDashboardMyOpenBids = dynamic(() => import('@app/components/mobile-dashboard-my-open-bids'), {
  ssr: false,
});

const DynamicBidstats = dynamic(() => import('@app/components/bids-stats'), {
  ssr: false,
});

const DynamicTabHeader = dynamic(() => import('@app/components/tab-header'), {
  ssr: false,
});

const DynamicUpcomingAndOpenBids = dynamic(() => import('@app/components/upcoming-and-open-bids'), {
  ssr: false,
});

const HomePage = () => {
  const router = useRouter();
  const { data: session } = useSession();
  const { currentUser } = useUserContext();
  const [currentMobileTabKey, setCurrentMobileTabKey] = useState('processing-bids');
  const { data: panelDataColumn1 } = useSWR('/bidding/dashboard/bids/me', { fallbackData: [] });
  const { data } = useSWR('/bidding/track-submitted-bids', { fallbackData: { panelData: [] } });
  const { panelData: panelDataColumn2 } = data;

  const dateToday = new Date();
  const { isMobile, isTablet } = useThemeContext();

  const MOBILE_TAB_OPTIONS = [
    {
      title: 'Bids in My Area',
      key: 'my-bids',
      component: <DynamicMobileDashboardMyOpenBids />,

    },
    {
      title: 'All Open Bids',
      key: 'all-bids',
      component: <DynamicMobileDashboardAllOpenBids />,
    },
  ];

  const activeMobileTab = useMemo(
    () => MOBILE_TAB_OPTIONS.find((t) => t.key === currentMobileTabKey) || MOBILE_TAB_OPTIONS[0],
    [currentMobileTabKey],
  );

  return (
    <AuthLayout>
      <section className="xs:p-6 p-3 md:px-12 h-full md:py-10">
        <div className="w-full lg:grid lg:grid-cols-3">
          <div className="w-full col-span-2">
            <div className="flex flex-row space-x-3">
              <DashboardAuthImage userImg={session.user.image} />
              <div className="flex flex-col justify-center">
                <p className="h4 lg:h3">
                  {`Hi${currentUser != null ? `, ${currentUser.firstName} ${currentUser.lastName}` : ''}!`}
                </p>
                <p className="text-body-sm dark:text-text-color-low-em">
                  {`Today is ${formatDate(dateToday.toString(), 'PPP')}`}
                </p>
              </div>
            </div>
            {!isMobile && (
              <div className="w-full">
                <div className="w-full h-24 flex items-center mb-2">
                  <BidStats panelData={panelDataColumn1} columnTwo={false} />
                </div>
                <div className="w-full flex items-center mb-5">
                  <BidStats panelData={panelDataColumn2} columnTwo />
                </div>
                {isTablet && (
                  <div className="hidden md:block lg:hidden pb-5 space-y-5">
                    <div className="grid grid-cols-2 gap-3">
                      <div className="h-1/2">
                        <DynamicUpcomingAndOpenBids />
                      </div>
                      <div className="h-1/2">
                        <DynamicRecentlyClosedBids />
                      </div>
                    </div>
                  </div>
                )}
                <DynamicVendorOpenBids />
              </div>
            )}
          </div>
          <div className="hidden lg:block">
            {!isMobile && (
              <div
                className="m-2 space-y-11 "
              >
                <DynamicHowToBidVideo />
                <div className="grid-cols-1 gap-8">
                  <div className="h-1/2">
                    <UpcomingAndOpenBids />
                  </div>
                  <div className="h-1/2">
                    <DynamicRecentlyClosedBids />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="pt-5 space-y-5">
            <DynamicBidstats panelData={panelDataColumn2} columnTwo />
            <DynamicTabHeader
              tabs={MOBILE_TAB_OPTIONS.filter((t) => t.key !== 'edit-profile')}
              currentTab={activeMobileTab.key}
              onChangeTab={(key) => setCurrentMobileTabKey(key.key)}
            />
            {activeMobileTab.component}
          </div>
        )}
      </section>
      <div className="md:hidden flex w-full px-6 pb-2 justify-center">
        <Button onClick={() => router.push(OPEN_BIDS_PAGE)} full buttonType="secondary" className="!p-px !rounded-lg">
          View All Open Bids
        </Button>
      </div>
    </AuthLayout>
  );
};

export default HomePage;

HomePage.requireAuth = true;
