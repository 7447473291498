import { TRACK_SUBMITTED_BIDS_PAGE } from '@app/constants/routes';
import { useRouter } from 'next/router';
import Button from './button';

export interface BidStatsDetailsProps {
  type: string;
  typeCount: number;
}

const BidStatDetailsColumnTwo = ({ type, typeCount }: BidStatsDetailsProps) => {
  const router = useRouter();
  let redirecto = '';

  switch (type) {
    case 'All My Bids':
      redirecto = `${TRACK_SUBMITTED_BIDS_PAGE}?tab=submitted`;
      break;
    case 'Bids Awarded':
      redirecto = `${TRACK_SUBMITTED_BIDS_PAGE}?tab=awarded`;
      break;
    case 'Bids Processing':
      redirecto = `${TRACK_SUBMITTED_BIDS_PAGE}?tab=processing`;
      break;
    default:
      redirecto = `${TRACK_SUBMITTED_BIDS_PAGE}?tab=declined`;
      break;
  }

  return (
    <div className="border border-background-stroke dark:border-background-stroke-dark rounded-xl">
      <div className="flex space-y-1 flex-col p-3">
        <p className="h4 text-text-color-high-em dark:text-text-color-high-em-dark">
          {`${typeCount} ${typeCount === 1 ? 'Bid' : 'Bids'}`}
        </p>
        <p className="label-sm text-text-color-mid-em dark:text-text-color-low-em pb-1">{type}</p>
        <Button onClick={() => router.push(redirecto)} full buttonType="secondary">View All</Button>
      </div>
    </div>
  );
};

export default BidStatDetailsColumnTwo;
