import { isEmpty } from 'lodash';
import useSWR from 'swr';
import { PulseLoader } from '@app/components/table-pagination';
import { RfpBidDetailsStats } from './recently-closed-bids';

/* eslint-disable camelcase */
export interface UpcomingAndOpenBidsDetailProps {
  rfp: {
    client: {
      name: string
    }
    start_date: string,
    end_date: string,
    rfp_item: any[],
  },
}
/* eslint-enable camelcase */

const UpcomingAndOpenBidsDetail = ({ rfp }: UpcomingAndOpenBidsDetailProps) => (
  <div className="bg-light dark:bg-background-main-dark p-1">
    <RfpBidDetailsStats rfp={rfp} />
  </div>
);

const UpcomingAndOpenBids = () => {
  const { data } = useSWR('/bidding/dashboard/open-and-upcoming-bids/me');

  return (
    <div className="flex-1 rounded-lg shadow-md p-2">
      <p
        className="flex text-text-color-high-em
       dark:text-text-color-high-em-dark
       items-center mb-2 label-lg"
      >
        <span>Upcoming & Current Bids</span>
      </p>
      <ul className="overflow-auto styled-overflow" style={{ height: '30vh', minHeight: '30vh' }}>
        {data ? data.map((d) => (
          <li key={d.id}>
            <UpcomingAndOpenBidsDetail rfp={d} />
          </li>
        )) : <PulseLoader />}
        {data && isEmpty(data) && (
          <li>
            <p className="text-body-sm text-text-color-mid-em dark:text-text-color-low-em-dark">
              No upcoming & current bids to display
            </p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default UpcomingAndOpenBids;
