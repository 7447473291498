import Link from 'next/link';
import { ReactNode } from 'react';
import {
  BidMyOpenIcon,
  BidOpenTotalIcon,
  BidWarningIcon,
  BidStatProcessingIcon,
  BidStatAwardedIcon,
} from './bid-stat-icon';

export interface BidStatsDetailsProps {
  type: string;
  typeCount: number;
}

const BidStatsDetails = ({ type, typeCount }: BidStatsDetailsProps) => {
  let currentIcon: ReactNode;

  switch (type) {
    case 'Bids in My Area':
      currentIcon = <BidMyOpenIcon />;
      break;
    case 'Total Open Bids':
      currentIcon = <BidOpenTotalIcon />;
      break;
    case 'Bids Warning':
      currentIcon = <BidWarningIcon />;
      break;
    case 'Bids Processing':
      currentIcon = <BidStatProcessingIcon />;
      break;
    case 'Bids Awarded':
      currentIcon = <BidStatAwardedIcon />;
      break;
    case 'Bids Declined':
      currentIcon = <BidOpenTotalIcon />;
      break;
    case 'All My Bids':
      currentIcon = <BidMyOpenIcon />;
      break;
    default:
      currentIcon = <BidMyOpenIcon />;
      break;
  }

  return (
    <div className={`flex flex-row space-x-3 
      items-center shadow-sm md:shadow-none p-1 md:p-0 rounded-lg md:rounded-none`}
    >
      <div className="ml-2 object-contain">
        {currentIcon}
      </div>
      <div className={`flex-col flex ${type === 'Total Open Bids' ? '-mb-5' : ''}`}>
        <p className="label-lg text-text-color-high-em
       dark:text-text-color-high-em-dark"
        >
          {`${!typeCount ? '0' : typeCount} Bids`}
        </p>
        <p className="label-sm text-text-color-mid-em dark:text-text-color-low-em cursor-default">
          {type}
        </p>
        {type === 'Total Open Bids' && (
          <span className="text-primary-dark dark:text-primary-light underline text-xs mt-1">
            <Link href={{ pathname: '/open-bids', query: { openSearchLink: true } }}>
              <span className="py-1 cursor-pointer">
                Search All
              </span>
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};

export default BidStatsDetails;
