/** TODO: use inline-svg for babel
nextjs to enable svg imports direclty as react components */
export const BidMyOpenIcon = () => (
  <svg
    width="33"
    height="60"
    viewBox="0 0 20 23"
    className="fill-current text-primary-light"
  >
    <path
      d="m14 2 6 6v12a2 2 0 0 1-2
        2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8Zm4
        18V9h-5V4H6v16h12Zm-6.8-1.54-2.75-3 1.16-1.16
        1.59 1.58 3.58-3.58 1.17 1.41-4.75 4.75Z"
    />
  </svg>
);

export const BidStatAwardedIcon = () => (
  <svg
    width="33"
    height="60"
    viewBox="0 0 20 23"
    className="fill-current text-primary-light"
  >
    <path
      d="M14 13V11L12 12L10 11V13L8 14L10
        15V17L12 16L14 17V15L16 14L14 13ZM14 2H7C6.46957
        2 5.96086 2.21071 5.58579 2.58579C5.21071 2.96086
        5 3.46957 5 4V18C5 18.5304 5.21071 19.0391 5.58579
        19.4142C5.96086 19.7893 6.46957 20 7
        20H8V18H7V4H13V8H17V18H16V20H17C17.5304
        20 18.0391 19.7893 18.4142 19.4142C18.7893
        19.0391 19 18.5304 19 18V7L14 2ZM14 13V11L12
        12L10 11V13L8 14L10 15V17L12 16L14 17V15L16
        14L14 13ZM10 23L12 22L14 23V18H10V23ZM14
        13V11L12 12L10 11V13L8 14L10 15V17L12
        16L14 17V15L16 14L14 13Z"
    />

  </svg>
);

export const BidStatProcessingIcon = () => (
  <svg
    width="33"
    height="60"
    viewBox="0 0 21 23"
    className="fill-current text-primary-light"
  >
    <path
      d="M12.8 22H6C4.9 22 4 21.1 4 20V4C4
        2.9 4.9 2 6 2H14L20 8V11.5C19.4 11.2 18.7
        11.1 18 11V9H13V4H6V20H11.5C11.8 20.7 12.3
        21.4 12.8 22ZM17 12L14.8 14.2L17 16.4V15C18.4
        15 19.5 16.1 19.5 17.5C19.5 17.9 19.4 18.3 19.2
        18.6L20.3 19.7C20.7 19.1 21 18.3 21 17.5C21 15.3
        19.2 13.5 17 13.5V12ZM19.2 20.8L17 18.5V20C15.6 20
        14.5 18.9 14.5 17.5C14.5 17.1 14.6 16.7 14.8 16.4L13.7
        15.3C13.3 15.9 13 16.7 13 17.5C13 19.7 14.8 21.5 17
        21.5V23L19.2 20.8Z"
    />
  </svg>
);

export const BidOpenTotalIcon = () => (
  <svg
    width="35"
    height="60"
    viewBox="0 0 23 23"
    className="fill-current text-primary-light"
  >
    <path
      d="M17 18C17.56 18 18 18.44 18 19C18 19.56 17.56
        20 17 20C16.44 20 16 19.56 16 19C16 18.44 16.44 18
        17 18ZM17 15C14.27 15 11.94 16.66 11 19C11.94 21.34
        14.27 23 17 23C19.73 23 22.06 21.34 23 19C22.06 16.66
        19.73 15 17 15ZM17 21.5C16.337 21.5 15.7011 21.2366 15.2322
        20.7678C14.7634 20.2989 14.5 19.663 14.5 19C14.5
        18.337 14.7634 17.7011 15.2322 17.2322C15.7011 16.7634
        16.337 16.5 17 16.5C17.663 16.5 18.2989 16.7634 18.7678
        17.2322C19.2366 17.7011 19.5 18.337 19.5 19C19.5 19.663
        19.2366 20.2989 18.7678 20.7678C18.2989 21.2366 17.663
        21.5 17 21.5ZM9.27 20H6V4H13V9H18V13.07C18.7 13.15 19.36
        13.32 20 13.56V8L14 2H6C5.46957 2 4.96086 2.21071 4.58579
        2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304
        4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957
        22 6 22H10.5C9.99562 21.3962 9.58132 20.7225 9.27 20Z"
    />
  </svg>
);

export const BidWarningIcon = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    className="fill-current text-primary-light"
  >
    <path
      d="M34 28.3334H37.3333V25H34V28.3334ZM34
      11.6667V21.6667H37.3333V11.6667H34ZM10.6667
      26.6667H19V30H10.6667V26.6667ZM10.6667
      20H24V23.3334H10.6667V20ZM7.33333 3.33337C5.48333
      3.33337 4 4.81671 4 6.66671V33.3334C4 35.1834
      5.48333 36.6667 7.33333 36.6667H27.3333C29.1833
      36.6667 30.6667 35.1834 30.6667 33.3334V13.3334L20.6667
      3.33337H7.33333ZM7.33333 6.66671H19V15H27.3333V33.3334H7.33333V6.66671Z"
    />
  </svg>

);
