import {
  mdiDumpTruck, mdiInformation, mdiLeaf, mdiMapMarker, mdiRecycleVariant, mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import { uniq, isEmpty } from 'lodash';
import useSWR from 'swr';
import { FeaturedBidDetails } from './featured-bid';
import { PulseLoader } from './table-pagination';
import Tooltip from './tooltip';

export const RfpBidDetailsStats = ({ rfp }: { rfp: any }) => {
  const rfpItemWasteStreams = rfp.rfp_item.map((item) => item.waste_stream.name);
  const rfpItemContainers = rfp.rfp_item.map((item) => item.container.container_type.name);

  const getUniqueValuesInArray = (array: Array<string>) => (
    uniq(array)
  );

  const tooltipfy = (array: Array<string>) => (
    array.length > 1 ? array.map((e) => `• ${e}`).join('<br />')
      : `• ${array[0]}`
  );

  return (
    <div className="bg-background-low-em dark:bg-background-low-em-dark p-4 rounded-xl flex flex-row space-x-3">
      <div>
        {rfp.client.img
          ? <img src={rfp.client.img} className="rounded-md" alt={rfp.client.name} width="60" />
          : (
            <div
              className="h-16 w-16 rounded-md flex items-center
          justify-center bg-secondary px-3 py-1
          text-2xl text-white"
            >
              <Icon
                size={1.5}
                path={mdiLeaf}
              />
            </div>
          )}
      </div>
      <div className="flex flex-col space-y-2">
        <p className="label-lg dark:text-text-color-mid-em-dark">
          {rfp.client.name}
        </p>
        <div className="
          text-text-color-mid-em
          text-body-md space-y-1
          dark:text-text-color-low-em
          "
        >
          <FeaturedBidDetails
            icon={mdiMapMarker}
            overrideTextClass="text-body-md text-text-color-mid-em dark:text-text-color-disabled"
            value={`${rfp.rfp_item.length} Locations`}
          />
          <FeaturedBidDetails
            icon={mdiTrashCan}
            overrideTextClass="text-body-md text-text-color-mid-em dark:text-text-color-disabled"
            value={`${rfp.rfp_item.length} Service Levels`}
          />
          {/* TODO: Confirm waste stream array */}
          <div className="flex flex-row space-x-2 items-center">
            <FeaturedBidDetails
              icon={mdiRecycleVariant}
              overrideTextClass="text-body-md text-text-color-mid-em dark:text-text-color-disabled"
              value={
                `${getUniqueValuesInArray(rfpItemWasteStreams).length} 
                Waste Stream${getUniqueValuesInArray(rfpItemWasteStreams).length > 1 ? 's' : ''}`
              }
            />
            <Tooltip id="wasteStreamTip" place="bottom" delayHide={100} multiline>
              <Icon
                data-class="tooltip-list"
                path={mdiInformation}
                size={0.8}
                data-tip={tooltipfy(getUniqueValuesInArray(rfpItemWasteStreams))}
                data-for="wasteStreamTip"
              />
            </Tooltip>
          </div>
          {/* TODO: Confirm container array */}
          <div className="flex flex-row space-x-2 items-center">
            <FeaturedBidDetails
              data-class="tooltip-list"
              icon={mdiDumpTruck}
              overrideTextClass="text-body-md text-text-color-mid-em dark:text-text-color-disabled"
              value={
                `${getUniqueValuesInArray(rfpItemContainers).length} 
                Container${getUniqueValuesInArray(rfpItemContainers).length > 1 ? 's' : ''}`
              }
            />
            <Tooltip id="containerTip" place="bottom" delayHide={100} multiline>
              <Icon
                data-class="tooltip-list"
                path={mdiInformation}
                size="sm"
                data-tip={tooltipfy(getUniqueValuesInArray(rfpItemContainers))}
                data-for="containerTip"
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO: rfp type
const ClosedBidDetails = ({ rfp }: { rfp: any }) => (
  <div className="bg-light dark:bg-background-main-dark p-1">
    <RfpBidDetailsStats rfp={rfp} />
  </div>
);

const RecentlyClosedBids = () => {
  const { data } = useSWR('/bidding/dashboard/recently-closed-bids');

  return (
    <div className="flex-1 rounded-lg shadow-md p-2">
      <p
        className="flex text-text-color-high-em
       dark:text-text-color-high-em-dark
       items-center mb-2 label-lg"
      >
        <span>Recently Closed Bids</span>
      </p>
      <ul className="space-y-6 overflow-auto styled-overflow" style={{ height: '30vh', minHeight: '30vh' }}>
        {data ? data.map((d) => <li key={d.id}><ClosedBidDetails rfp={d} /></li>) : <PulseLoader />}
        {data && isEmpty(data) && (
          <p className="text-body-sm text-text-color-mid-em dark:text-text-color-low-em-dark">
            No recently closed bids to display
          </p>
        )}
      </ul>
    </div>
  );
};

export default RecentlyClosedBids;
