import BidStatDetailsColumnTwo from './bid-stats-details-column-2';
import BidStatsDetails from './bids-stats-details';

export interface BidStatsProps {
  panelData: Array<{ title: string, count: number }>;
  columnTwo?: boolean
}

const BidStats = ({
  panelData, columnTwo = false,
}: BidStatsProps) => (
  columnTwo ? (
    <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-3">
      {panelData.map((d, i) => (
        <BidStatDetailsColumnTwo type={d.title} key={i} typeCount={d.count} />
      ))}
    </div>
  ) : (
    <div className="w-full grid grid-cols-2 md:grid-cols-4 md:divide-x gap-3 md:gap-4">
      {panelData.map((d, i) => (
        <BidStatsDetails type={d.title} key={i} typeCount={d.count} />
      ))}
    </div>
  )
);

export default BidStats;
